module.exports = [{
      plugin: require('/Users/scenicwest/Desktop/Fudo-An/hello-world/new-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139795363-1"},
    },{
      plugin: require('/Users/scenicwest/Desktop/Fudo-An/hello-world/new-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/scenicwest/Desktop/Fudo-An/hello-world/new-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
